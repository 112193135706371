import React from 'react'
import { graphql } from 'gatsby'
import styled from 'styled-components'
import parse from 'html-react-parser'
import Layout from '../layout/Layout'
import SEO from '../components/seo'
import Video from '../components/Video'
import Line from '../components/Line'
import PaddedBox from '../components/PaddedBox'
import Link from '../components/Link'
import getVideoImage from '../utils/getVideoImage'
import Tag from '../components/Tag'
import isBrowser from '../utils/isBrowser'
// import quoteIcon from "../assets/dot-line--grey.svg"

const Tags = styled.ul`
  display: inline-block;
  padding-left: 0;
`

const StyledLink = styled(Link)`
  color: white;
`

const Transcript = styled.div`
  padding-left: 1rem;
  border-left: 0.5rem solid #ddd;
`

const StyledBackLink = styled(Link)`
  position: absolute;
  top: 1rem;
  right: 1rem;
`

const Blockquote = styled.blockquote``
//   position: relative;
//   margin-left: -1rem;
//   margin-right: -1rem;
//   padding: 1rem 3rem;
//   background-color: ${p('ipYellowDark')};
//   font-size: 1.5rem;
//   font-style: italic;
//
//  strong:before {
//    content: '"';
//    /* position: absolute;
//    top: 0.5rem;
//    left: 0.5rem; */
//    display: inline;
//    font-family: OpenDyslexicBold;
//    font-size: 3rem;
//    color: white;
//  }
//`

const VideoDetail = ({ data }) => {
  const {
    community,
    description,
    duration,
    url,
    quote1,
    // quote2,
    tags,
    title,
    transcript,
  } = data.airtable.data

  const images = data.allWordpressWpMedia.nodes
  const { image, isImageFound } = getVideoImage(title, images)

  // duration is an integer representing seconds
  const parsedDuration = duration
    ? `${Math.floor(duration / 60)}:${duration % 60}`
    : 'N/A'

  const hasTranscript = !!transcript.length
  let formattedTranscript, parsedTranscript
  if (hasTranscript) {
    formattedTranscript = transcript
      .split('\n')
      .map(
        line =>
          `<p>${line
            .replace(/^([\w ]+): (.*)/, '<b>$1:</b> $2')
            .replace(/(\[.*\])/g, '<i>$1</i>')}</p>`
      )
      .filter(line => line !== '<p></p>')
      .join('\n')

    parsedTranscript = parse(formattedTranscript)
  }

  return (
    <Layout>
      <SEO title={`${title} - ${community}`} />

      <PaddedBox>
        <h1 id="top">{title}</h1>
        <h2>({community})</h2>

        {isBrowser() && window.history.length ? (
          <StyledBackLink
            to="#"
            onClick={event => {
              event.preventDefault()
              window.history.back()
            }}
          >
            Back to Stories
          </StyledBackLink>
        ) : (
          <StyledBackLink to="/stories-from-the-community/">
            Show all stories
          </StyledBackLink>
        )}

        <Video
          className={!isImageFound && 'image-missing'}
          image={image}
          title={title}
          url={url}
          duration={parsedDuration}
        />

        <p>{description}</p>

        {quote1 && (
          <Blockquote className="large-italic with-icon">
            {parse(quote1)}
          </Blockquote>
        )}

        {tags && (
          <div className="Tags">
            Talks about:{' '}
            <Tags>
              {tags
                .split(',')
                .filter(t => t.search(/ ?(interplay|ndis)/i)) // don't pring NDIS or interplay tags
                .map((tag, idx) => (
                  <Tag key={`${idx}-${tag}`}>
                    <StyledLink
                      to={`/stories-from-the-community/?tag=${encodeURIComponent(
                        tag
                      )}`}
                      className="u-tdn text-white"
                    >
                      {tag}
                    </StyledLink>
                  </Tag>
                ))}
            </Tags>
          </div>
        )}

        <Line />

        {hasTranscript && (
          <>
            <h2>Transcript</h2>
            <Transcript className="Video__transcript">
              {parsedTranscript}
            </Transcript>
          </>
        )}

        <p>
          <a href="#top">Back to top</a>
        </p>
      </PaddedBox>
    </Layout>
  )
}

export const pageQuery = graphql`
  query($id: String!) {
    airtable(id: { eq: $id }) {
      data {
        community
        description
        duration
        quote1
        quote2
        tags
        title
        transcript
        url
      }
    }

    allWordpressWpMedia {
      nodes {
        id
        title
        alt_text
        localFile {
          childImageSharp {
            fluid(maxWidth: 1280) {
              src
              originalName
              originalImg
              ...GatsbyImageSharpFluid_withWebp
            }
            original {
              src
            }
          }
          name
          publicURL
          sourceInstanceName
          relativeDirectory
        }
      }
    }
  }
`

export default VideoDetail
